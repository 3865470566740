/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Scroll from 'react-scroll';
import Footer from './footer/footer';
import NavBar from './navbar/navbar';
import Contact from './sections/contact/contact';
import Resume from './sections/resume/resume';
import SimpleIntro from './sections/simple-intro/simple-intro';

const Element = Scroll.Element;
const scroller = Scroll.scroller;

const Main = ({
  data: { clients, contact, profile, references, resume, roles, services, techs },
  onLanguageChange
}) => {
  // eslint-disable-next-line no-unused-vars
  const [activeSection, setActiveSection] = useState('');

  const sections = [];

  const handleGoToSection = section => {
    scroller.scrollTo(section, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: 0
    });
  };

  return (
    <div>
      <NavBar
        activeSection={activeSection}
        onGoToSection={handleGoToSection}
        onLanguageChange={onLanguageChange}
      />
      <Element name="intro">
        <SimpleIntro
          onGoToProfile={() => {
            handleGoToSection('profile');
          }}
        />
      </Element>
      <div style={{ height: 2000, width: '100%', backgroundColor: '#d1d2e5' }} />
      {/* <Element name="profile">
        <Profile profile={profile} roles={roles} techs={techs} />
        <Hobbies hobbies={profile.hobbies} />
      </Element>
      <Element name="services">
        <Services services={trunkArray(services, 3)} />
        <References references={references} />
      </Element> */}
      <Element name="resume">
        <Resume resume={resume} goToSection={handleGoToSection} />
        {/* <Clients clients={clients} /> */}
      </Element>
      {/* <Element name="portfolio">
        <Portfolio />
      </Element> */}
      <Element name="contact">
        <Contact contact={contact} />
      </Element>
      <Footer />
    </div>
  );
};

Main.propTypes = {
  data: PropTypes.shape({
    clients: PropTypes.array,
    contact: PropTypes.object,
    profile: PropTypes.object,
    references: PropTypes.array,
    resume: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    services: PropTypes.array,
    techs: PropTypes.array
  }).isRequired,
  onLanguageChange: PropTypes.func.isRequired
};

export default Main;
