import classNames from 'classnames';
import React, { useState } from 'react';
import Typist from 'react-typist';
import styles from './under-construction.module.scss';

const UnderConstruction = () => {
  const [showRoles, setShowRoles] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);

  const handleShowRoles = () => {
    setShowRoles(true);
    setTimeout(() => {
      setShowComingSoon(true);
    }, 1000);
  };

  return (
    <div className={styles['under-construction']}>
      <section>
        <h1>
          <Typist
            stdTypingDelay={15}
            startDelay={1500}
            cursor={{ show: false }}
            onTypingDone={handleShowRoles}
          >
            <span className={styles['under-construction__hello']}>Hello, I&apos;m </span>
            <span>Pablo Ibanez</span>
          </Typist>
        </h1>
        <h3
          className={classNames(
            styles['under-construction__roles'],
            showRoles ? styles['under-construction__roles--shown'] : null
          )}
        >
          <span>Software Engineer</span>
          <span>Technical Lead</span>
          <span>Full Stack Developer</span>
        </h3>
        <div
          className={classNames(
            styles['under-construction__coming-soon'],
            showComingSoon ? styles['under-construction__coming-soon--shown'] : null
          )}
        >
          <div className={styles['under-construction__post-it']}>
            <div className={styles['under-construction__post-it__title']}>Coming back soon...</div>
            <div className={styles['under-construction__post-it__text']}>
              ...meantime you can take a look at my LinkedIn profile...
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/pablo-ibanez-dev/"
                className={styles['under-construction__post-it__link']}
              >
                <i className="icon-linkedin-square" />
                <span>Pablo Ibanez</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UnderConstruction;
