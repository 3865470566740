import React from 'react';
import { FormattedMessage } from 'react-intl';
import profile from '../../../data/profile.json';
import styles from './footer.module.scss';

const footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer id="footer" className={styles.footer}>
      <div className={styles.footer__text}>
        <FormattedMessage id="FOOTER.MAIN" /> Pablo Ibanez - {year}
      </div>
      <div className={styles.footer__links}>
        {profile.socialProfiles.map(pr => (
          <a key={pr.url} target="_blank" rel="noopener noreferrer" href={pr.url}>
            {pr.name}
          </a>
        ))}
      </div>
    </footer>
  );
};

export default footer;
