import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-scroll';
import sections from '../../../data/sections.json';
import LanguageSelector from './language-selector/language-selector';
import styles from './navbar.module.scss';

const fixedNavbarBound = 60;

const filteredSections = sections
  .filter(s => s.navBarOrder > 0)
  .sort((a, b) => a.navBarOrder - b.navBarOrder);

// eslint-disable-next-line no-unused-vars
const Navbar = ({ onLanguageChange }) => {
  const [showSections, setShowSections] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!isFixed && window.pageYOffset > fixedNavbarBound) {
        setIsFixed(true);
      } else if (isFixed && window.pageYOffset <= fixedNavbarBound) {
        setIsFixed(false);
      }
    };

    const checkIfClickOutside = evt => {
      if (
        showSections &&
        !evt.path.some(p => p.className && p.className.includes('sections-list'))
      ) {
        setShowSections(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('click', checkIfClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('click', checkIfClickOutside);
    };
  }, [isFixed, showSections]);

  const toggleSections = () => {
    setShowSections(!showSections);
  };

  return (
    <nav
      className={classNames(
        styles.navbar,
        isFixed ? styles['navbar--fixed'] : styles['navbar--top']
      )}
    >
      <div className="container">
        <div className={styles.navbar__wrapper}>
          <div className={styles.navbar__left}>
            <div className={styles.navbar__header}>Pablo Ibanez</div>
            <div>
              <button className={styles.navbar__toggle} type="button" onClick={toggleSections}>
                <span className="sr-only">Toggle navigation</span>
                <i className="icon-menu" />
              </button>
            </div>
          </div>
          <div className={styles.navbar__right}>
            <div
              className={classNames(
                styles['navbar__list-wrapper'],
                showSections ? styles['navbar__list-wrapper--shown'] : null
              )}
            >
              <ul className={styles['navbar__sections-list']}>
                {filteredSections.map(s => {
                  return (
                    <li key={s.name}>
                      <Link
                        activeClass={styles['navbar__active-link']}
                        to={s.name}
                        spy
                        smooth
                        offset={-70}
                        duration={500}
                      >
                        <FormattedMessage id={`NAVBAR.${s.navlink}`} />
                      </Link>
                    </li>
                  );
                })}
                <LanguageSelector isFixed={isFixed} onLanguageChange={onLanguageChange} />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  onLanguageChange: PropTypes.func.isRequired
};

export default Navbar;
