import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import PreloaderLoading from './preloader-loading/preloader-loading';
import styles from './preloader.module.scss';

const Preloader = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 1000);
  }, []);

  return (
    <div className={classNames(styles.preloader, isLoaded ? styles['preloader--fadeOut'] : null)}>
      <div className={styles.preloader__loading}>
        <PreloaderLoading />
      </div>
    </div>
  );
};

export default Preloader;
