// import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { addLocaleData } from 'react-intl';
import intlEN from 'react-intl/locale-data/en';
import intlES from 'react-intl/locale-data/es';
import App from './App';
import './assets/scss/index.scss';
import registerServiceWorker from './registerServiceWorker';

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

addLocaleData([...intlEN, ...intlES]);

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
