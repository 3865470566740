/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Typist from 'react-typist';
import roles from '../../../../data/roles.json';
import styles from './simple-intro.module.scss';

const SimpleIntro = ({ onGoToProfile }) => {
  const [height, setHeight] = useState();
  const [activeRoleIndex, setActiveRoleIndex] = useState(0);
  const [showRoles, setShowRoles] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setHeight(window.innerHeight);
    setTimeout(() => {
      setShowRoles(true);
    }, 4000);
    setTimeout(() => {
      setShowButton(true);
    }, 5000);
    setInterval(() => {
      nextRoleIndex();
    }, 3000);
  }, []);

  const nextRoleIndex = () => {
    setActiveRoleIndex(activeRoleIndex < roles.length ? activeRoleIndex + 1 : 0);
  };

  return (
    <section className={styles['simple-intro']} style={{ height }}>
      <div className={styles['simple-intro__center-container']}>
        <div className={styles['simple-intro__title-wrapper']}>
          <h1>
            <FormattedMessage id="INTRO.HELLO_IM">
              {msg => {
                return (
                  <Typist
                    stdTypingDelay={15}
                    startDelay={1500}
                    cursor={{ show: false }}
                    onTypingDone={nextRoleIndex}
                  >
                    <span className={styles['simple-intro__hello']}>{msg}</span>
                    <span>Pablo Ibanez</span>
                  </Typist>
                );
              }}
            </FormattedMessage>
          </h1>
          <div
            className={classNames(
              styles['simple-intro__roles'],
              showRoles ? styles['simple-intro__roles--shown'] : null
            )}
          >
            <h3>
              {roles.map((r, i) => (
                <FormattedMessage key={i} id={r} />
              ))}
            </h3>
          </div>
        </div>
        <div
          className={classNames(
            styles['simple-intro__button-wrapper'],
            showButton ? styles['simple-intro__button-wrapper--shown'] : null
          )}
        >
          <button type="button" className={styles['simple-intro__button']} onClick={onGoToProfile}>
            <FormattedMessage id="INTRO.BUTTON_TEXT" />
          </button>
        </div>
      </div>
      <a className={styles['simple-intro__mouse-icon']} onClick={onGoToProfile}>
        <div>
          <div />
        </div>
      </a>
    </section>
  );
};

SimpleIntro.propTypes = {
  onGoToProfile: PropTypes.func
};

SimpleIntro.defaultProps = {
  onGoToProfile: () => {}
};

export default SimpleIntro;
