import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { IntlProvider } from 'react-intl';
import Main from './components/main/main';
import Preloader from './components/preloader/preloader';
import UnderConstruction from './components/under-construction/under-construction';
import data from './data';
import environment from './environment.json';
import flattenMessages from './util/flattenMessages';

const getLangTranslations = lang => flattenMessages(require(`./i18n/${lang}.json`));

const getCurrentLang = () => {
  return localStorage.getItem('lang') || 'en';
};

const App = () => {
  const [lang, setLang] = useState(getCurrentLang());

  useEffect(() => {
    ReactGA.initialize(environment.GOOGLE_ANALYTICS_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleChangeLanguage = newLang => setLang(newLang);

  return (
    <IntlProvider key={lang} locale={lang} messages={getLangTranslations(lang)}>
      <div className="wrapper">
        <Preloader />
        {environment.UNDER_CONSTRUCTION === 'true' ? (
          <UnderConstruction />
        ) : (
          <Main data={data} onLanguageChange={handleChangeLanguage} />
        )}
      </div>
    </IntlProvider>
  );
};

export default App;
