import clients from './clients.json';
import contact from './contact.json';
import places from './places.json';
import profile from './profile.json';
import profileContact from './profileContact.json';
import references from './references.json';
import resume from './resume.json';
import roles from './roles.json';
import services from './services.json';
import techs from './techs.json';

export default {
  clients,
  contact,
  places,
  profile,
  profileContact,
  references,
  resume,
  roles,
  services,
  techs
};
