import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import contact from '../../../../data/contact.json';
import environment from '../../../../environment.json';
import styles from './contact.module.scss';
import mapConfig from './map.config.json';

const Contact = () => {
  useEffect(() => {
    loadGoogleMaps(renderMap);
  }, []);

  const loadGoogleMaps = onLoad => {
    const script = document.createElement(`script`);
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_MAPS_API_KEY}`;
    document.head.append(script);
    script.addEventListener(`load`, onLoad);
    return () => script.removeEventListener(`load`, onLoad);
  };

  const renderMap = () => {
    // eslint-disable-next-line no-new
    new window.google.maps.Map(document.getElementById('contact-map'), {
      ...contact.map,
      ...mapConfig
    });
  };

  return (
    <section className={styles.contact}>
      <div className={styles['contact__map-overlay']} />
      <div id="contact-map" className={styles.contact__map} />
      <div className={styles.contact__content}>
        <div className="container">
          <h2>
            <FormattedMessage id="CONTACT.TITLE" />
          </h2>
          <h4>
            <FormattedMessage id="CONTACT.SUBTITLE" />
          </h4>
          <div className={styles.contact__row}>
            <div className={styles['contact__contact-methods']}>
              <div className={styles['contact__contact-method']}>
                <i className="icon-location" />
                <span>{contact.location}</span>
              </div>
              <div className={styles['contact__contact-method']}>
                <i className="icon-mail" />
                <a href="mailto:me@pabloibanez.com">me@pabloibanez.com</a>
              </div>
              <div className={styles['contact__contact-method']}>
                <i className="icon-earth" />
                <a href="http://www.pabloibanez.com">www.pabloibanez.com</a>
              </div>
              <div className={styles['contact__contact-method']}>
                <i className="icon-linkedin" />
                <a href="https://www.linkedin.com/in/pablo-ibanez-dev">
                  www.linkedin.com/in/pablo-ibanez-dev
                </a>
              </div>
            </div>
            <div className={styles['contact__form-container']}>
              <form className={styles.contact_form}>
                {contact.form.map(fe => (
                  <div className={styles['contact__form-group']} key={fe.name}>
                    <FormattedMessage id={`CONTACT.FORM.${fe.placeholder}`}>
                      {placeholder => (
                        <fe.element
                          rows={fe.rows}
                          type={fe.type}
                          className={styles['contact__form-input']}
                          name={`c_${fe.name}`}
                          placeholder={placeholder}
                        />
                      )}
                    </FormattedMessage>
                  </div>
                ))}
                <button type="submit" className={styles['contact__form-button']}>
                  <FormattedMessage id="CONTACT.FORM.SEND" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
