import classNames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './resume-element.module.scss';

const ResumeElement = ({ experience }) => {
  const [showSecondary, setShowSecondary] = useState(false);

  const handleToggleSecondary = () => {
    setShowSecondary(!showSecondary);
  };

  return (
    <div className={styles['resume-element']}>
      <div className={styles['resume-element__column-experience-description']}>
        <h4>{experience.role}</h4>
        <div className={styles['resume-element__experience-element']}>
          <h5>
            <i className="icon-techstack" />
            <FormattedMessage id="RESUME.TECH_STACK" />
          </h5>
          <p>{experience.techStack && experience.techStack.join(' | ')}</p>
        </div>
        <div
          className={classNames(
            styles['resume-element__secondary-elements'],
            showSecondary ? styles['resume-element__secondary-elements--shown'] : null
          )}
        >
          <div className={styles['resume-element__experience-element']}>
            <h5>
              <i className="icon-responsabilities" />
              <FormattedMessage id="RESUME.KEY_RESPONSABILITIES" />
            </h5>
            <ul>
              {experience.responsabilities &&
                experience.responsabilities.map((rs, i) => <li key={i}>{rs}</li>)}
            </ul>
          </div>
          <div className={styles['resume-element__experience-element']}>
            <h5>
              <i className="icon-pencil" />
              <FormattedMessage id="RESUME.ONE_THING_I_LEARNT" />
            </h5>
            <p>{experience.learnt}</p>
          </div>
          <div className={styles['resume-element__experience-element']}>
            <h5>
              <i className="icon-achievements" />
              <FormattedMessage id="RESUME.WHAT_WE_ACHIEVED" />
            </h5>
            <ul>
              {experience.achievements &&
                experience.achievements.map((rs, i) => <li key={i}>{rs}</li>)}
            </ul>
          </div>
        </div>
        <a
          onClick={handleToggleSecondary}
          className={styles['resume-element__show-secondary-link']}
        >
          <div>
            {showSecondary ? (
              <FormattedMessage id="RESUME.VIEW_LESS" />
            ) : (
              <FormattedMessage id="RESUME.VIEW_MORE" />
            )}
            <i
              className={classNames(
                `icon-angle-${showSecondary ? 'up' : 'right'}`,
                styles['resume-element__expand-icon'],
                !showSecondary ? styles['resume-element__expand-icon--expanded'] : null
              )}
            />
          </div>
        </a>
        <hr />
      </div>
      <div className={styles['resume-element__column-experience-place']}>
        <h4>
          <i className="icon-briefcase" />
          {experience.title}
        </h4>
        <div>
          <span>
            <i className="icon-calendar" />
            {experience.period}
          </span>
        </div>
        <div>
          <span>
            <i className="icon-location" />
            {experience.location}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResumeElement;
