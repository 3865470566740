import React from 'react';
import { FormattedMessage } from 'react-intl';
import resume from '../../../../data/resume.json';
import ResumeElement from './resume-element/resume-element';
import styles from './resume.module.scss';

// eslint-disable-next-line no-unused-vars
const experience = exp => (
  <div className={styles.resume__row}>
    <div className={styles['resume__column-experience-description']}>
      <h4>{exp.role}</h4>
      <div className={styles['resume__experience-element']}>
        <h5>
          <i className="icon-techstack" />
          <FormattedMessage id="RESUME.TECH_STACK" />
        </h5>
        <p>{exp.techStack && exp.techStack.join(' | ')}</p>
      </div>
      <div className={styles['resume__experience-element']}>
        <h5>
          <i className="icon-responsabilities" />
          <FormattedMessage id="RESUME.KEY_RESPONSABILITIES" />
        </h5>
        <ul>
          {exp.responsabilities && exp.responsabilities.map((rs, i) => <li key={i}>{rs}</li>)}
        </ul>
      </div>
      <div className={styles['resume__experience-element']}>
        <h5>
          <i className="icon-pencil" />
          <FormattedMessage id="RESUME.ONE_THING_I_LEARNT" />
        </h5>
        <p>{exp.learnt}</p>
      </div>
      <div className={styles['resume__experience-element']}>
        <h5>
          <i className="icon-achievements" />
          <FormattedMessage id="RESUME.WHAT_WE_ACHIEVED" />
        </h5>
        <ul>{exp.achievements && exp.achievements.map((rs, i) => <li key={i}>{rs}</li>)}</ul>
      </div>
      <hr />
    </div>
    <div className={styles['resume__column-experience-place']}>
      <h4>
        <i className="icon-briefcase" />
        {exp.title}
      </h4>
      <div>
        <span>
          <i className="icon-calendar" />
          {exp.period}
        </span>
      </div>
      <div>
        <span>
          <i className="icon-location" />
          {exp.location}
        </span>
      </div>
    </div>
  </div>
);

const Resume = () => {
  return (
    <section className={styles.resume}>
      <div className="container">
        <h2>
          <FormattedMessage id="RESUME.TITLE" />
        </h2>
        <h4>
          <FormattedMessage id="RESUME.SUBTITLE" />
        </h4>
        <div className={styles.resume__row}>
          <div className={styles['resume__column-group']}>
            <h3>Experience</h3>
          </div>
          <div className={styles['resume__column-experiences']}>
            {resume.experience.map(exp => (
              <ResumeElement experience={exp} />
            ))}
          </div>
        </div>
        <div className={styles.resume__row}>
          <div className={styles['resume__column-group']}>
            <h3>Education</h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
