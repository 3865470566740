import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './language-selector.module.scss';

const languageLabel = lang => (lang === 'en' ? 'English' : 'Español');

const LanguageSelector = ({ isFixed, onLanguageChange }) => {
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'en');
  const [showDropdown, setShowDropdown] = useState(false);

  const getOtherLanguage = () => {
    return lang === 'en' ? 'es' : 'en';
  };

  const handleChangeLanguage = newLang => {
    setLang(newLang);
    localStorage.setItem('lang', newLang);
    onLanguageChange(newLang);
  };

  return (
    <>
      <li className={classNames(styles['language-selector'], styles['language-selector--desktop'])}>
        <div onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
          <a>
            {languageLabel(lang)}
            <i className="icon-angle-down" />
          </a>
          <ul
            className={classNames(
              styles['language-selector__dropdown'],
              isFixed ? styles['language-selector__dropdown--fixed'] : null,
              showDropdown ? styles['language-selector__dropdown--shown'] : null
            )}
          >
            <li>
              <a onClick={() => handleChangeLanguage('en')}>English</a>
            </li>
            <li>
              <a onClick={() => handleChangeLanguage('es')}>Español</a>
            </li>
          </ul>
        </div>
      </li>
      <li className={classNames(styles['language-selector'], styles['language-selector--mobile'])}>
        <a onClick={() => handleChangeLanguage(getOtherLanguage())}>
          <FormattedMessage id="NAVBAR.CHANGE_LANGUAGE" />{' '}
        </a>
      </li>
    </>
  );
};

LanguageSelector.propTypes = {
  isFixed: PropTypes.bool,
  onLanguageChange: PropTypes.func.isRequired
};

LanguageSelector.defaultProps = {
  isFixed: false
};

export default LanguageSelector;
