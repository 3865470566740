import React from 'react';
import styles from './preloader-loading.module.scss';

const PreloaderLoading = () => (
  <div className={styles['lds-grid']}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default PreloaderLoading;
